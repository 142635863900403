import { Text } from '@react-three/drei'
import React, { useEffect, useState, useLayoutEffect, useRef } from 'react'
import gsap from 'gsap'
import { useLocation } from 'wouter'

const TorusGeometry = () => {
  const [location] = useLocation()

  const torusCompRef = useRef()
  const meshOneRef = useRef()
  const meshTwoRef = useRef()
  const meshThreeRef = useRef()
  const textOneRef = useRef()
  const textThreeRef = useRef()

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  })

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      })
    }

    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [dimensions])

  const positionGroup = dimensions.width / dimensions.height
  const positionGroupX = positionGroup < 1 ? -0.5 : positionGroup

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      let targetsMeshes = gsap.utils.toArray([
        meshOneRef,
        meshTwoRef,
        meshThreeRef,
      ])
      let targetsText = gsap.utils.toArray([textOneRef, textThreeRef])

      targetsMeshes.forEach((target) => {
        gsap.set(target.current.scale, { x: 0, y: 0, z: 0 })
        gsap.to(target.current.scale, {
          x: 1,
          y: 1,
          z: 1,
          duration: 1.2,
          ease: 'back',
        })
      })

      targetsText.forEach((text) => {
        gsap.to(text.current.rotation, { z: -Math.PI * 0.5, duration: 1.5 })
      })
    }, torusCompRef)

    return () => ctx.revert()
  }, [])

  useEffect(() => {
    if (
      location === '/react' ||
      location === '/JS' ||
      location === '/gatsby' ||
      location === '/svelte' ||
      location === '/mern' ||
      location === '/threeJS'
    ) {
      gsap.to(torusCompRef.current.position, {
        x: -5,
        y: 0.5,
        z: 1,
        duration: 1,
        delay: 1,
      })
      gsap.to(torusCompRef.current.scale, {
        x: 1.2,
        y: 1.2,
        z: 1.2,
      })
    } else {
      gsap.to(torusCompRef.current.position, {
        x: positionGroupX,
        y: 0.5,
        z: 0,
        duration: 1,
        delay: 1,
      })
      gsap.to(torusCompRef.current.scale, {
        x: 1.4,
        y: 1.4,
        z: 1.4,
      })
    }
  }, [location, positionGroupX])

  return (
    <>
      <group position={[positionGroupX, 0.5, 0]} scale={1.4} ref={torusCompRef}>
        <Text fontSize={0.7} position={[1, 2.4, 0]} ref={textOneRef}>
          P
        </Text>
        <Text fontSize={0.7} position={[1.5, 0.35, 0]}>
          RTF
        </Text>
        <Text fontSize={0.7} position={[1, -1.6, 0]} ref={textThreeRef}>
          LI
        </Text>
        <mesh position={[1.5, 1.5, 0]} ref={meshOneRef}>
          <torusGeometry args={[0.7, 0.04, 16]} />
          <meshBasicMaterial />
        </mesh>
        <mesh position={[1.5, -0.6, 0]} ref={meshTwoRef}>
          <torusGeometry args={[0.7, 0.04, 16]} />
          <meshBasicMaterial color='#FFF3E2' />
        </mesh>
        <mesh position={[1.5, -2.6, 0]} ref={meshThreeRef}>
          <torusGeometry args={[0.7, 0.04, 16]} />
          <meshBasicMaterial color='#FFF3E2' />
        </mesh>
      </group>
    </>
  )
}

export default TorusGeometry
