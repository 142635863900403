import React from 'react'
import styled from 'styled-components'

const Loader = ({ location }) => {
  return (
    <LoaderWrapper className='loader'>
      <div className='g'>
        <div
          className='top'
          style={{ zIndex: `${location.pathname !== '/' ? 1 : 0}` }}
        ></div>
        <div className='middle'></div>
        <div className='bottom'></div>
      </div>
    </LoaderWrapper>
  )
}

const LoaderWrapper = styled.section`
  .top {
    position: absolute;
    top: 0;
    left: 0;
    height: 50%;
    color: white;
    background-color: black;
    width: 100%;
    /* z-index: 1; */
  }
  .bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 50%;
    color: white;
    background-color: black;
    width: 100%;
    z-index: 1;
  }
  .middle {
    position: fixed;
    top: 50%;
    left: 50%;
    background-color: white;
    transform: translate(-50%, -50%);
    width: 30px;
    height: 3px;
    z-index: 1;
  }
`

export default Loader
