import React, { useLayoutEffect, useRef } from 'react'
import { Outlet } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { ReactComponent as MainLogo } from '../mainLogo.svg'
import Split from './Split'
import gsap from 'gsap'

const Navbar = () => {
  const navbarCompRef = useRef()
  const tlContactRef = useRef()
  const tlMainLogoRef = useRef()

  useLayoutEffect(() => {
    let mm = gsap.matchMedia(navbarCompRef)

    gsap.set('.mainLogo', { autoAlpha: 0, scale: 0.35 })
    gsap.set('.linkContainer', { autoAlpha: 0, xPercent: 50 })
    mm.add('(min-width: 1024px)', () => {
      tlMainLogoRef.current = gsap
        .timeline({ paused: true })
        .to('.mainLogo', { rotate: 360, scale: 1.2 }, '<')

      gsap.set('.wrapper', { yPercent: 100 })
      gsap.set('.letter', { yPercent: 100 })

      tlContactRef.current = gsap
        .timeline({ paused: true })
        .to('.span', { yPercent: -100 })
        .to('.wrapper', { yPercent: 0 }, '<')
        .to('.letter', { stagger: 0.1, yPercent: 0 }, '<')
    })
    mm.add('(max-width: 1023px)', () => {
      tlMainLogoRef.current = gsap
        .timeline({ paused: true })
        .to('.mainLogo', { rotate: 0 }, '<')

      tlContactRef.current = gsap.timeline({ paused: true })
    })

    return () => mm.revert()
  }, [])

  const handleMouseOver = () => {
    tlMainLogoRef.current.play()
  }
  const handleMouseLeave = () => {
    tlMainLogoRef.current.reverse()
  }
  const handleMouseOverLink = () => {
    tlContactRef.current.play()
  }
  const handleMouseLeaveLink = () => {
    tlContactRef.current.reverse()
  }
  const handleClick = ({ currentTarget }) => {
    gsap.fromTo(
      currentTarget,
      { scale: 1 },
      {
        scale: 0.75,
        duration: 0.25,
        yoyo: true,
        repeat: 1,
        //  overwrite: true
      }
    )
  }

  return (
    <NavbarWrapper ref={navbarCompRef}>
      <div>
        <Link
          to='/'
          onMouseEnter={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          className='logoBtn'
        >
          <MainLogo className='mainLogo' onClick={handleClick} />
        </Link>
      </div>
      <div className='linkContainer'>
        <Link
          to='/contact'
          className='link'
          onMouseEnter={handleMouseOverLink}
          onMouseLeave={handleMouseLeaveLink}
        >
          <div className='span'>Contacts</div>
          <Split>Contacts</Split>
        </Link>
      </div>
      {/* <div className='line'></div> */}
      <Outlet />
    </NavbarWrapper>
  )
}

const NavbarWrapper = styled.section`
  width: 90vw;
  margin: 0 auto;
  height: 3rem;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1240px;

  .mainLogo {
    fill: var(--target-project-page-titles-and-p-color);
    display: grid;
    place-items: center;
    width: 50px;
    transform-origin: center;
    @media screen and (max-width: 320px) {
      width: 40px;
    }
    @media screen and (min-width: 321px) {
      width: 40px;
    }
    @media screen and (min-width: 425px) {
      width: 50px;
    }
    /* display: flex;
    align-self: center; */
  }
  .linkContainer {
    overflow: hidden;
    position: relative;
  }
  .link {
    text-decoration: none;
    color: var(--target-project-page-titles-and-p-color);
    font-size: 1rem;
    letter-spacing: 0.02rem;
    padding: 1rem 0rem;
    /* overflow: hidden; */
    @media screen and (max-width: 320px) {
      font-size: 0.8rem;
    }
    @media screen and (min-width: 321px) {
      font-size: 0.8rem;
    }
    @media screen and (min-width: 425px) {
      font-size: 1rem;
    }
  }
  .span {
    font-family: 'Cabinet', serif;
    font-weight: 600;
    letter-spacing: 0.02rem;
  }
  .wrapper {
    position: absolute;
    top: 0;
    left: 0;
  }
  .letter {
    font-family: 'Cabinet', serif;
    font-weight: 600;
    letter-spacing: 0.02rem;
  }
`

export default Navbar

// useLayoutEffect(() => {
//   let mm = gsap.matchMedia(navbarCompRef)

//   mm.add('(min-width: 1024px)', () => {
//     tlMainLogoRef.current = gsap
//       .timeline({ paused: true })
//       .to('.mainLogo', { rotate: 360, scale: 1.2 }, '<')

//     gsap.set('.wrapper', { yPercent: 100 })
//     gsap.set('.letter', { yPercent: 100 })

//     tlContactRef.current = gsap
//       .timeline({ paused: true })
//       .to('.span', { yPercent: -100 })
//       .to('.wrapper', { yPercent: 0 }, '<')
//       .to('.letter', { stagger: 0.1, yPercent: 0 }, '<')
//   })

//   return () => mm.revert()
// }, [])

// const ctx = useRef()

// useLayoutEffect(() => {
//   ctx.current = gsap.context(() => {
//     // gsap.set('.mainLogo', { autoAlpha: 0, scale: 0.35 })
//     // gsap.set('.linkContainer', { autoAlpha: 0, xPercent: 50 })
//     // gsap.to('.mainLogo', { autoAlpha: 1, scale: 1, delay: 0.7 })
//     // gsap.to('.linkContainer', {
//     //   autoAlpha: 1,
//     //   delay: 0.7,
//     //   xPercent: 0,
//     //   duration: 2,
//     // })
//     gsap.set('.wrapper', { yPercent: 100 })
//     gsap.set('.letter', { yPercent: 100 })
//     tlContactRef.current = gsap
//       .timeline({ paused: true })
//       .to('.span', { yPercent: -100 })
//       .to('.wrapper', { yPercent: 0 }, '<')
//       .to('.letter', { stagger: 0.1, yPercent: 0 }, '<')

//     tlMainLogoRef.current = gsap
//       .timeline({ paused: true })
//       .to('.mainLogo', { rotate: 360, scale: 1.2 }, '<')
//   }, navbarCompRef)

//   return () => ctx.current.revert()
// }, [])
