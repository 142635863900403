import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import Model from './components/canvas/Model'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <React.StrictMode>
    <Model />
    <BrowserRouter>
      {/* <Helmet>
        <title>Sergey Malin | Developer | Portfolio | Projects </title>
        <meta
          name='description'
          content='Your go-to destination for web development solutions that push the boundaries of innovation. As a website developer, I understand the importance of staying ahead in this rapidly evolving digital landscape. That`s why I am here to equip you with the tools, knowledge, and inspiration to create exceptional online experiences.'
        />
      </Helmet> */}
      <App />
    </BrowserRouter>
  </React.StrictMode>
)
