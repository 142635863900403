import React, { useLayoutEffect, useRef } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const FixedTitle = ({ category, location }) => {
  const fixedTitleRef = useRef()
  const tl = useRef()

  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      gsap.set('.link', { autoAlpha: 0, xPercent: -100 })
      gsap.set('.titleH4', { autoAlpha: 0, xPercent: -100 })

      tl.current = gsap
        .timeline()
        .to('.link', {
          autoAlpha: 1,
          xPercent: 0,
          ease: 'power4',
          duration: 1.4,
          delay: 2,
        })
        .to('.titleH4', {
          autoAlpha: 1,
          xPercent: 0,
          ease: 'power4',
          duration: 1.4,
        })
    }, fixedTitleRef)

    return () => ctx.revert()
  }, [])

  useLayoutEffect(() => {
    let mm = gsap.matchMedia(fixedTitleRef)

    mm.add(
      {
        isMobile: '(max-width: 768px)',
      },
      (context) => {
        const { isMobile } = context.conditions

        gsap.to(fixedTitleRef.current, {
          yPercent: isMobile ? -100 : '',
          zIndex: 2,
          scrollTrigger: {
            trigger: fixedTitleRef.current,
            start: 'top top',
            end: fixedTitleRef.current.offsetHeight,
            // markers: true,
            toggleActions: 'play none reverse none',
          },
        })
      }
    )

    return () => mm.revert()
  }, [])

  const handleMouseOver = ({ currentTarget }) => {
    gsap.to(currentTarget, { fontSize: '1rem' })
  }
  const handleMouseLeave = ({ currentTarget }) => {
    gsap.to(currentTarget, { fontSize: '0.8rem' })
  }
  return (
    <CategoryTitleWrapper ref={fixedTitleRef}>
      <div className='wrapper'>
        <div className='left'>
          <Link
            to='/projects'
            className='link'
            onMouseEnter={handleMouseOver}
            onMouseLeave={handleMouseLeave}
          >
            &#8592; Back
          </Link>
          <h4 className='titleH4'>
            {category}
            <br /> projects
          </h4>
        </div>
        <div className='right'></div>
      </div>
    </CategoryTitleWrapper>
  )
}

const CategoryTitleWrapper = styled.div`
position: fixed;
/* bottom: 0; */
/* background-color: black; */
left:0;
/* z-index: -1; */

  /* position: fixed; */
  /* height: 100vh; */
  /* left: 0px;
  top: 2rem;
  width: calc(100% - 650px);
  padding: 10px;
   */
  @media screen and (max-width: 319px) {
    /* display: flex;
    flex-direction: row; */
    position: fixed;
    top: 3rem;
    left:0
    height: 2rem;
    width: 100%;
    /* background-color: black; */
    z-index:1;
    /* width: 95vw; */
    /* margin: 0 auto; */
    /* justify-content: space-between;
    align-items: center; */
  }
  @media screen and (min-width: 320px) {
 position: fixed;
    top: 3rem;
    left:0
    height: 2rem;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
     /* z-index:1; */
  }
  @media screen and (min-width: 768px) {
   
min-height: calc(100vh - 3rem);
width: 100%;
  }
  .wrapper {
        
    max-width: 1240px;
    @media screen and (max-width: 319px) {
       display: flex;
    flex-direction: row;
      justify-content: space-between;
    align-items: center;
    width: 90vw;
    margin: 0 auto;
    }
       @media screen and (min-width: 320px) {
       display: flex;
    flex-direction: row;
      justify-content: space-between;
    align-items: center;
    width: 90vw;
    margin: 0 auto;
    }

@media screen and (min-width: 768px) {
  width: 90vw;
 min-height: calc(100vh - 3rem);
 display: grid;
 grid-template-columns: 1fr 2fr;
 

 /* flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;  */
}
    
  }
  a {
    margin-left: 2rem;
    margin-top: 2rem;
    text-decoration: none;
    color: var(--background-section-color);
    font-family: 'Cabinet', serif;
    font-weight: 700;
    font-size: 0.8rem;
    letter-spacing: 0.02rem;
    @media screen and (max-width: 320px) {
       margin-left: 0rem;
    margin-top: 0rem;
    }
     @media screen and (min-width: 320px) {
       margin-left: 0rem;
    margin-top: 0rem;
    }
      @media screen and (min-width: 768px) {
       
    margin-top: 2rem;
    }
  }
  .wrapper .left h4 {
    font-size: 3rem;
    text-transform: capitalize;
    font-family: 'Mobilla', sans-serif;
    line-height: 1.15;
    color: #b3aa9e;
    text-align: center;
    margin-left: 3rem;
    /* z-index: -1;
    margin-bottom: 3rem; */
    @media screen and (max-width: 320px) {
      font-size: 1rem;
    }
     @media screen and (min-width: 320px) {
      font-size: 1rem;
    }
       @media screen and (min-width: 768px) {
      font-size: 3rem;
      margin-left: 0rem;
      margin-bottom: 1rem;
    }
  }
  .opacity {
    transform: translateX(0);
  }
  .transform {
    transform: translateX(-100%);
  }
  .left {
        width: 100%;
    display: flex;
    height: 100%;
    flex-direction: column;
    align-content: flex-start;
    justify-content: space-between;
    align-items: flex-start;
    @media screen and (min-width: 320px) {
       display: flex;
    flex-direction: row;
    align-items: center;
    }
    @media screen and (min-width: 768px) {
      flex-direction: column;
      a {
        align-self: start;
      }
    }
  }
`

export default FixedTitle
