import React, { useRef, useContext } from 'react'
import { useLocation } from 'react-router'
import { SwitchTransition, Transition } from 'react-transition-group'
import TransitionContext from './TransitionContext'
import gsap from 'gsap'

const TransitionWrapper = ({ children }) => {
  const location = useLocation()
  const nodeRef = useRef()
  const { toggleCompleted } = useContext(TransitionContext)
  return (
    <SwitchTransition>
      <Transition
        appear
        key={location.pathname}
        timeout={3500}
        nodeRef={nodeRef}
        onEnter={() => {
          const node = nodeRef.current
          toggleCompleted(false)
          gsap.set(node, { autoAlpha: 0, scale: 0.8, xPercent: -100 })
          gsap
            .timeline({
              paused: true,
              onComplete: () => toggleCompleted(true),
            })
            .to(node, { autoAlpha: 1, xPercent: 0, duration: 0.5 })
            .to(node, { scale: 1, duration: 0.5 })
            .play()
        }}
        onExit={() => {
          const node = nodeRef.current
          gsap
            .timeline({ paused: true })
            .to(node, { scale: 0.8, duration: 1 })
            .to(node, { xPercent: 100, autoAlpha: 0, duration: 1 })
            .play()
        }}
      >
        <div ref={nodeRef}>{children}</div>
      </Transition>
    </SwitchTransition>
  )
}

export default TransitionWrapper
