import React from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'

const SEO = () => {
  return (
    <HelmetProvider>
      <div>
        <Helmet>
          <title>Sergey Malin | Developer | Portfolio | Projects </title>
          <meta
            name='description'
            content='Your go-to destination for web development solutions that push the boundaries of innovation. As a website developer, I understand the importance of staying ahead in this rapidly evolving digital landscape. That`s why I am here to equip you with the tools, knowledge, and inspiration to create exceptional online experiences.'
          />
        </Helmet>
      </div>
    </HelmetProvider>
  )
}

export default SEO
