import './App.css'
import 'reset-css'
import './index.css'
import React, {
  useEffect,
  useState,
  useLayoutEffect,
  useRef,
  Suspense,
} from 'react'
import gsap from 'gsap'
import { TransitionProvider } from './components/TransitionContext'
import { Routes, Route, useLocation } from 'react-router-dom'
import TransitionWrapper from './components/TransitionWrapper'
import FixedTitle from './components/FixedTitle'
import Navbar from './components/Navbar'
import Loader from './components/Loader'
import SEO from './components/SEO'

const Hero = React.lazy(() => import('./components/pages/Hero.js'))
const Projects = React.lazy(() => import('./components/pages/Projects.js'))
const Contact = React.lazy(() => import('./components/pages/Contact'))
const TargetProjectPage = React.lazy(() =>
  import('./components/pages/TargetProjectPage')
)

function App() {
  const [animationComplete, setAnimationComplete] = useState(false)

  const [isLocationTrue, setIsLocationTrue] = useState(false)
  const [category, setCategory] = useState('')

  const location = useLocation()
  const name = location.pathname

  function getLocation(name) {
    if (
      name === '/react' ||
      name === '/gatsby' ||
      name === '/mern' ||
      name === '/svelte' ||
      name === '/JS' ||
      name === '/threeJS'
    ) {
      setIsLocationTrue(true)
      setCategory(name.slice(1))
    } else {
      setIsLocationTrue(false)
      setCategory('')
    }
  }

  useEffect(() => {
    getLocation(name)
  }, [location, name])

  let timeline = useRef()
  const mainAppRef = useRef()
  useLayoutEffect(() => {
    let ctx = gsap.context(() => {
      // gsap.set('.mainLogo', { autoAlpha: 0, scale: 0.35 })
      // gsap.set('.linkContainer', { autoAlpha: 0, xPercent: 50 })

      gsap.set('.middle', { autoAlpha: 0, height: 0 })

      timeline.current = gsap
        .timeline({ paused: true, css: { overflow: 'hidden' } })
        .to(document.body, { css: { overflow: 'hidden' } })
        .to('.middle', { autoAlpha: 1, height: '3px', delay: 1 })
        .to('.middle', { width: '100%', duration: 3, delay: 1 })
        .to('.middle', { display: 'none', autoAlpha: 0 })
        .to('.top', { xPercent: 100, duration: 1 })
        .to(
          '.bottom',
          {
            xPercent: -100,
            duration: 1,
            onComplete: () => {
              setAnimationComplete(true)
              gsap.to('.text', { autoAlpha: 1 })
              gsap.to('.mainLogo', { autoAlpha: 1, scale: 1 })
              gsap.to('.linkContainer', { autoAlpha: 1, xPercent: 0 })
              gsap.to(document.body, { css: { overflow: 'auto' } })
            },
          },
          '<'
        )
    }, mainAppRef)

    return () => ctx.revert()
  }, [])

  useLayoutEffect(() => {
    timeline.current.play()
  }, [])

  useEffect(() => {
    location.pathname === '/'
      ? (document.body.style.overflow = 'hidden')
      : (document.body.style.overflow = 'auto')
  }, [location])

  return (
    <div ref={mainAppRef}>
      {animationComplete === false ? <Loader location={location} /> : ''}
      {isLocationTrue && (
        <FixedTitle category={category} isLocationTrue={isLocationTrue} />
      )}
      <Navbar />
      <TransitionProvider>
        <Routes>
          <Route
            path='/'
            element={
              <Suspense fallback={null}>
                <TransitionWrapper>
                  <Hero
                    timeline={timeline}
                    setAnimationComplete={setAnimationComplete}
                    animationComplete={animationComplete}
                  />
                  {/* <Footer /> */}
                </TransitionWrapper>
              </Suspense>
            }
          />

          <Route
            path='/projects'
            element={
              <Suspense fallback={null}>
                <TransitionWrapper>
                  <Projects />
                </TransitionWrapper>
              </Suspense>
            }
          />
          <Route
            path='/contact'
            element={
              <Suspense fallback={null}>
                <TransitionWrapper>
                  <Contact />
                </TransitionWrapper>
              </Suspense>
            }
          />
          <Route
            path={`/${location.pathname}`}
            element={
              <Suspense fallback={null}>
                <TransitionWrapper>
                  <TargetProjectPage location={location.pathname} />
                </TransitionWrapper>
              </Suspense>
            }
          />
        </Routes>
      </TransitionProvider>
      <SEO />
    </div>
  )
}

export default App
