import { PerspectiveCamera } from '@react-three/drei'
import { Canvas } from '@react-three/fiber'
import React from 'react'
import TorusGeometry from './TorusGeometry'
import { EffectComposer } from '@react-three/postprocessing'
import Background from '../functions/Background'
import styled from 'styled-components'

const Model = () => {
  return (
    <CanvasContainerWrapper>
      <Canvas>
        {/* <Suspense fallback={<Loader />}> */}
        <PerspectiveCamera position={[0, 0, 10]} makeDefault />
        <TorusGeometry />
        <EffectComposer>
          <Background />
        </EffectComposer>
        {/* </Suspense> */}
      </Canvas>
    </CanvasContainerWrapper>
  )
}

const CanvasContainerWrapper = styled.section`
  width: 100vw;
  height: 100lvh;
  position: fixed;
  z-index: -10;
  overflow: hidden;
`
export default Model
