import React from 'react'
import styled from 'styled-components'

const Split = ({ children }) => {
  const string = children.split('')
  return (
    <SplitWrapper className='wrapper'>
      {string.map((item, index) => {
        return (
          <span className='letter' key={index}>
            {item}
          </span>
        )
      })}
    </SplitWrapper>
  )
}
const SplitWrapper = styled.div`
  /* position: absolute;
  top: 0;
  left: 0;
  transform: translate(0, 105%); */
  display: flex;
  @media screen and (max-width: 320px) {
    justify-content: flex-end;
  }
  .letter {
    display: block;
  }
`

export default Split
