import React, { createContext } from 'react'
import { useState } from 'react'

const TransitionContext = createContext({ completed: false })

export const TransitionProvider = ({ children }) => {
  const [completed, setCompleted] = useState(false)
  const [dimensions, setDimensions] = useState({
    height: 0,
    width: 0,
  })

  const toggleCompleted = (value) => {
    setCompleted(value)
  }

  return (
    <TransitionContext.Provider
      value={{
        toggleCompleted,
        completed,
        dimensions,
      }}
    >
      {children}
    </TransitionContext.Provider>
  )
}

export default TransitionContext
